<template>
  <div class="scroll_card">
    <div class="head">
      <div class="title">{{title}}</div>
      <div class="more">
        <!-- <img src="@/static/icon-更多@2x.png"
            
             alt=""> -->
        <span @click="toPage">更多</span>
      </div>
    </div>

    <div class="list_warp">
      <div class="center"
           id="items"
           ref="itemRefs">
        <!-- 首页，知识点弱点模块 -->
        <a class="item"
           title="点击训练"
           v-for="(item,index) in list"
           :key="index">

          <div class="text"
               @click="toInfo(item)">
            <div class="desc">
              {{item.knowledge_desc || item.lecture_name}}
            </div>
            <!-- <div class="card">
              {{item.subject_name}}
            </div> -->
            <div class="score">
              <div>
                得分率(%): <span style="color:#FB9930;padding-right:20rem">{{item.rate}}</span>
              </div>
              <div>
                <img src="@/static/star1.png"
                     v-for="start in item.knowledge_point_star"
                     :key="start"
                     alt="">
              </div>
            </div>
          </div>
          <!-- <div class="opertion">

            <div class="btn_warp">
              <div class="btn"
                   @click="toInfo(item)">
                {{btn_text}}
              </div>
            </div>
          </div> -->
        </a>
      </div>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from "@vue/runtime-core"
import { useRouter } from "vue-router"
// import { card_backgColor } from './property'

// const backgColor = card_backgColor
const itemRefs = ref(null)
const timeInterValId = ref(0)
const router = useRouter()
const { title, list, url, btn_text, showCard } = defineProps({
  title: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: []
  },
  url: {
    type: String,
    default: ''
  },
  btn_text: {
    type: String,
    default: ''
  },
  showCard: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['toInfo'])

onMounted(() => {
  let oitems = document.getElementById('items')
  oitems.addEventListener('touchstart', () => {
    clearVal()
  })
})

const clearVal = () => {
  clearInterval(timeInterValId.value)
}

const toInfo = (row) => {
  emit('toInfo', row)
}

const toPage = () => {
  router.push('/knowledgeWeak')
}

</script>

<style lang="scss" scoped>
.scroll_card {
  position: relative;
  width: 480rem;
  max-width: 28.9%;
  height: 370rem;
  overflow: hidden;
  margin-left: 40rem;
  background: rgba(1, 61, 253, 0.6);
  padding: 32rem 32rem 0rem;
  border-radius: 48rem;
  overflow: hidden;
  scrollbar-color: rgba(255, 255, 255, 0.6) rgba(255, 255, 255, 0.1);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;
  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  // border: 2rem solid #003dff;
}
.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  .title {
    font-weight: 500;
    font-size: 24rem;
    color: #ffffff;
    line-height: 36rem;
  }
  .more {
    font-size: 24rem;
    color: rgb(255, 255, 255, 0.6);
    cursor: pointer;
  }
}
.list_warp {
  height: 288rem;
  // display: flex;
  // flex-wrap: wrap;
  overflow: hidden;
  width: calc(100% + 15rem);
  .center {
    flex: 1;
    // display: flex;
    overflow-y: scroll;
    height: 100%;
    // margin-top: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding-left: 5px;
    padding-bottom: 16rem;
    .item {
      display: flex;
      color: #ffffff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.38);
      padding: 16rem 0;
      align-items: center;
      margin-right: 8rem;

      &:first-of-type {
        padding: 6rem 0 16rem;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      .text {
        flex: 1;
        line-height: 30rem;
        font-size: 20rem;
        cursor: pointer;
      }
      .desc {
        font-weight: 400;
        // padding-top: 10rem;
      }
      .score {
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-top: 8rem;
        color: rgba(255, 255, 255, 0.6);
        img {
          width: 20rem;
          height: 20rem;
          margin-right: 4rem;
          vertical-align: middle;
        }
      }
      .btn {
        font-size: 26rem;
        padding: 5rem 20rem;
        margin: 0 10rem;
        background: rgba(0, 55, 236, 0.5);
        border-radius: 30rem;
        cursor: pointer;
      }
    }
    /* 滚动条轨道. */
    &::-webkit-scrollbar-track {
      margin-bottom: 32rem;
    }
  }
}

::-webkit-scrollbar-thumb {
  background-color: #bed7ffbb;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 15rem;
  /* background-color: #f5f5f5; */
}
</style>
<template>
  <div class="scroll_card">
    <div class="head">
      <div class="title">{{title}}</div>
      <div class="more">
        <!-- <img src="@/static/icon-更多@2x.png"
             @click="toPage"
             alt=""> -->
        <span @click="toPage">更多</span>
      </div>
    </div>

    <div class="list_warp">
      <div class="center"
           id="items"
           ref="itemRefs">
        <!-- 首页，复习讲义模块 -->
        <div class="item"
             v-for="(item,index) in list"
             :key="index">
          <div class="text"
               :style="{'color':backgColor[item.subject_id].textcolor,'background':backgColor[item.subject_id].background}">
            <div class="desc">
              {{item.desc || item.lecture_name}}
            </div>
            <div class="card"
                 :class="{'card_long':item.subject_name=='道德与法治'?true:false}">
              {{item.subject_name}}

            </div>
          </div>
          <div class="opertion">
            <div class="score">
              <!-- <div>
                得分率:<span style="color:#FF6147 ">{{item.rate}}</span> 
              </div>-->
              <div>
                <span style="padding-right:4rem">频率:</span>
                <img src="@/static/star1.png"
                     v-for="start in item.star"
                     :key="start"
                     alt="">
              </div>
            </div>
            <div class="btn_warp">
              <div class="btn"
                   @click="toInfo(item)">
                {{btn_text}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { onMounted, ref } from "@vue/runtime-core"
import { useRouter } from "vue-router"
import { card_backgColor } from './property'

const backgColor = card_backgColor
const itemRefs = ref(null)
const timeInterValId = ref(0)
const router = useRouter()
const { title, list, url, btn_text, showCard } = defineProps({
  title: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: []
  },
  url: {
    type: String,
    default: ''
  },
  btn_text: {
    type: String,
    default: ''
  },
  showCard: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['toInfo'])

onMounted(() => {
  let oitems = document.getElementById('items')
  oitems.addEventListener('touchstart', () => {
    clearVal()
  })
})

const clearVal = () => {
  clearInterval(timeInterValId.value)
}

const toInfo = (row) => {
  emit('toInfo', row)
}

const toPage = () => {
  router.push('/knowledge')
}

</script>

<style lang="scss" scoped>
.scroll_card {
  position: relative;
  width: 1000rem;
  max-width: 60.2%;
  height: 370rem;
  margin-left: 44rem;
  background: rgba(1, 61, 253, 0.6);
  padding: 32rem 0rem;
  border-radius: 48rem;
  overflow: hidden;
  scrollbar-color: rgba(255, 255, 255, 0.6) rgba(255, 255, 255, 0.1);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;
  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
}
.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  .title {
    padding: 0 32rem;
    font-size: 24rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 36rem;
  }
  .more {
    font-size: 24rem;
    padding-right: 32rem;
    color: rgb(255, 255, 255, 0.6);
    cursor: pointer;
    line-height: 36rem;
  }
}
.list_warp {
  display: flex;
  overflow: hidden;

  .center {
    flex: 1;
    display: flex;
    overflow-x: scroll;
    margin-top: 6rem;
    -webkit-transition: all 1s;
    transition: all 1s;
    padding: 0 32rem 10rem 32rem;
    &::-webkit-scrollbar-thumb {
      background-color: #bed7ffbb;
    }
    /* 滚动条上的按钮 (上下箭头). */
    &::-webkit-scrollbar-button {
      height: 16rem;
      width: 16rem;
      display: block;
      opacity: 0;
    }
    /* 整个滚动条 */
    &::-webkit-scrollbar {
      height: 15rem;
    }
    .item {
      flex-shrink: 0;
      position: relative;
      width: 240rem;
      height: 246rem;
      background: #ffffff;
      border-radius: 20rem;
      margin-right: 24rem;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
      &:last-of-type {
        margin-right: 0;
      }
      .text {
        width: 224rem;
        height: 120rem;
        // background: #D2DCF9;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12rem;
        margin: 8rem;

        position: relative;
        background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        .card {
          height: 42rem;
          padding: 2rem 14rem 10rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../../../static/card1.png");
          background-size: 100% 108%;
          font-size: 20rem;
          font-weight: 400;
          color: #ffffff;
          line-height: 30rem;
          background-repeat: no-repeat;
          position: absolute;
          left: -12rem;
          top: 3rem;
        }
        .card_long {
          background-image: url("../../../static/card1_long.png");
        }
      }
      .desc {
        display: inline-block;
        text-align: center;
        // text-overflow: -o-ellipsis-lastline;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
        // -webkit-box-orient: vertical;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 114rem;
        // margin-top: 20rem;
        padding: 34rem 16rem 16rem;
        line-height: 36rem;
        font-size: 24rem;
        font-weight: 500;
      }
      .opertion {
        .score {
          display: flex;
          margin: 12rem 27rem;
          text-align: center;
          justify-content: center;
          div {
            display: flex;
            align-items: center;
            color: #999999;
            font-size: 20rem;
            font-weight: 400;
            line-height: 30rem;

            img {
              width: 20rem;
              height: 20rem;
              margin-left: 4rem;
              vertical-align: middle;
            }
          }
        }
        .btn_warp {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12rem;
          .btn {
            font-size: 20rem;
            font-weight: 400;
            color: #666666;
            line-height: 30rem;
            padding: 7rem 24rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100rem;
            border: 1rem solid #999;
            cursor: pointer;
            // box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
          }
        }
      }
    }
  }
}
.s1 {
  background-image: url("../../../static/goodVideo/bg-语文@2x.png");
}
.s7 {
  background-image: url("../../../static/goodVideo/bg-道德与法治@2x.png");
}
.s10 {
  background-image: url("../../../static/goodVideo/bg-化学@2x.png");
}
.s5 {
  background-image: url("../../../static/goodVideo/bg-化学@2x.png");
}
.s6 {
  background-image: url("../../../static/goodVideo/bg-历史@2x.png");
}
.s9 {
  background-image: url("../../../static/goodVideo/bg-生物@2x.png");
}
.s2 {
  background-image: url("../../../static/goodVideo/bg-数学@2x.png");
}
.s4 {
  background-image: url("../../../static/goodVideo/bg-物理@2x.png");
}
.s3 {
  background-image: url("../../../static/goodVideo/bg-英语@2x.png");
}
</style>
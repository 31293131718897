export const card_backgColor = {
  1: {
    // 用于展示首页和复习讲义不同颜色
    background: '#D2DCF9',
    textcolor: '#0D2878',
    // 用于展示公益视频 不同科目的颜色
    videoColor: '#557EF2'
  },
  2: {
    background: '#D2F9F4',
    textcolor: '#0D7866',
    videoColor: '#44DDA9'
  },
  3: {
    background: '#F9F1D2',
    textcolor: '#784B0D',
    videoColor: '#FFBD4E'
  },
  4: {
    background: '#F9DED2',
    textcolor: '#78390D',
    videoColor: '#FF954E'
  },
  5: {
    // 化学   暂时没有
    background: '#D2ECF9',
    textcolor: '#0D2C78',
    videoColor: '#37D5F9',
  },
  6: {
    background: '#DED2F9',
    textcolor: '#2B0D78',
    videoColor: '#AD5FF6',

  },
  7: {
    background: '#F9D2D2',
    textcolor: '#780D0D',
    videoColor: '#FF654E'
  },
  9: {
    background: '#DCE6D8',
    textcolor: ' #576156',
    videoColor: '#FF954E',
  },
  10: {
    background: '#F9E6BC',
    textcolor: '#6A5D3C',
    videoColor: '#37D5F9',
  },
}